import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([{
  alias: '/pct',
  children: [
  {
    component: () => import(/* webpackChunkName: "waitingroom" */ './PreCallTest.vue'),
    meta: {
      label: 'precall.title',
      secure: false,
    },
    path: '',
  }],
  component: () => import(/* webpackChunkName: "waitingroom" */ '../common/CommonFullLayout.vue'),
  meta: {
    secure: false,
    visible: false,
  },
  path: '/precall',
}]);

<template>
  <div>
    <template v-if="!setup">
      <v-app>
        <primeit-validate-current-time />
      </v-app>
    </template>
    <template v-else-if="setup">
      <router-view />
    </template>
    <template v-else>
      <div class="loader">
        <div class="pulse" />
      </div>
    </template>
    <dav-timezone v-if="userTimezone" :input-timezone="userTimezone" />
  </div>
</template>

<script>
import { Config, FeatureDetection } from '@dav/security-component-spa';
import merge from 'deepmerge';
import Vue from 'vue';

export default {
  data: () => ({
    userTimezone: null,
  }),
  computed: {
    setup() {
      return this.$store.getters['credential/SETUP'];
    },
    translation() {
      return this.$store.getters['credential/GET_TRANSLATION'];
    },
  },
  watch: {
    setup(value) {
      if (value && value.theme && value.theme.light) {
        this.$vuetify.theme.themes.light = Object.assign(this.$vuetify.theme.themes.light, value.theme.light);

        if (value.theme.room && value.theme.room.light) {
          const configs = value.theme.room.light;
          for (const config of configs) {
            document.documentElement.style.setProperty(config.code, config.value);
          }
        }
      }
    },
    translation(value) {
      const currentTranslation = this.$vuetify.lang.locales[this.getCurrentLanguage()];
      const translationData = merge(currentTranslation, value);
      this.$vuetify.lang.locales[this.getCurrentLanguage()] = translationData;
    },
  },
  beforeCreate() {
    const url = new URL(document.location.href);
    if (url.searchParams.has('t')) {
      /// Consultorio isTotem
      sessionStorage.setItem('isTotem', 'true'); /// Sou totem também
    }
  },
  async created() {
    const [config] = await Promise.all([
      Config.getInstance(),
      FeatureDetection.getAllFeatures(),
      this.$store.commit('common/SKIP_POPULATE_CURRENT_USER'),
    ]);

    this.userTimezone = window.localStorage.getItem('timezone');
    const vm = this;
    const behalf = config.get('dav-saas-behalf-of');
    const parts = /(?<domain>.*)\/(?<module>.*)/.exec(behalf);
    const environment = parts.groups.domain.endsWith('.dev')
      ? '.dev'
      : parts.groups.domain.endsWith('.hom')
      ? '.hom'
      : '';

    Vue.prototype.$dav = {
      config,
      isTotem: await FeatureDetection.isTotem(),
      url: {
        get atendimento() {
          let attendanceUrl;
          if (vm.setup.url_domain_atendimento === undefined) {
            attendanceUrl = `${vm.setup.url_domain}.atendimento${environment}.dav.med.br`;
          } else {
            attendanceUrl = vm.setup.url_domain_atendimento;
          }
          return attendanceUrl;
        },
        get consultorio() {
          let url;
          if (vm.setup.url_domain_consultorio === undefined) {
            url = `${vm.setup.url_domain}${environment}.dav.med.br`;
          } else {
            url = vm.setup.url_domain_consultorio;
          }
          return url;
        },
      },
    };
  },
  mounted() {
    this.$root.$on('userTimezone', (val) => {
      window.localStorage.setItem('timezone', val);
      this.userTimezone = val;
    });
  },
  methods: {
    getClinicaDomainName() {
      return 'atendimento' + window.location.host.substring(0, 10);
    },
    getCurrentLanguage() {
      const lang = navigator.language || navigator.userLanguage;
      let current;
      switch (lang) {
        case 'en':
        case 'en-US':
          current = 'en-US';
          break;

        case 'pt':
        case 'pt-BR':
        default:
          current = 'pt-BR';
          break;
      }
      return current;
    },
  },
};
</script>

<style>
@import url('//fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic|Material+Icons&display=swap');

html,
body {
  overflow-x: hidden;
  overflow-y: auto;
}

.theme--light.v-application {
  background: #fcfcfc;
}

.loader {
  position: absolute;
  position: fixed;
  z-index: 900;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

/* pulse animation */
.pulse {
  height: 200px;
  width: 200px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
}

.pulse:after {
  content: '';
  display: block;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100% 50%" enable-background="new 0 0 100% 50%" xml:space="preserve"><polyline fill="none" stroke-width="3px" stroke="grey" points="2.4,58.7 70.8,58.7 76.1,46.2 81.1,58.7 89.9,58.7 93.8,66.5 102.8,22.7 110.6,78.7 115.3,58.7 126.4,58.7 134.4,54.7 142.4,58.7 197.8,58.7 "/></svg>')
    0 0 no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  -webkit-animation: 2s pulse linear infinite;
  animation: 2s pulse linear infinite;
  clip: rect(0, 0, 100px, 0);
}

.pulse:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 2px;
  right: 2px;
  bottom: 0;
  top: 16px;
  margin: auto;
  height: 3px;
}

@-webkit-keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
    opacity: 0.4;
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
    opacity: 0.6;
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
    opacity: 0.8;
  }
  20% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 1;
  }
  80% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
  90% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
  100% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    clip: rect(0, 0, 100px, 0);
  }
  4% {
    clip: rect(0, 66.66667px, 100px, 0);
  }
  15% {
    clip: rect(0, 133.33333px, 100px, 0);
  }
  20% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 1;
  }
  80% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    clip: rect(0, 300px, 100px, 0);
    opacity: 0;
  }
}
</style>

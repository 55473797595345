import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([{
  children: [{
    path: '/login',
    redirect: '/',
  }, {
    component: () => import(/* webpackChunkName: "Common" */ './HomeView.vue'),
    meta: {
      label: 'home',
      secure: true,
    },
    path: '',
  }],
  component: () => import(/* webpackChunkName: "Common" */ '../common/CommonView.vue'),
  meta: {
    icon: 'home',
    label: 'home',
    order: 0,
    visible: false,
  },
  path: '/',
}]);

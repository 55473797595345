import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([{
    alias: '/d/:id',
    children: [{
      component: () => import(/* webpackChunkName: "room" */ `./Attendance`),
      meta: {
        label: 'doctoroffice.room',
        secure: false,
      },
      path: '',
    }],
    component: () => import(/* webpackChunkName: "room" */ '../common/CommonFullLayout.vue'),
    meta: {
      secure: false,
      visible: false,
    },
    path: '/doctoroffice/:id',
  },
  {
    alias: '/a/:id',
    children: [{
      component: () => import(/* webpackChunkName: "accessroom" */ './AccessRoom.vue'),
      meta: {
        label: 'doctoroffice.room',
        secure: false,
      },
      path: '',
    }],
    component: () => import(/* webpackChunkName: "accessroom" */ '../common/CommonFullLayout.vue'),
    meta: {
      secure: false,
      visible: false,
    },
    path: '/accessroom/:id',
  },
  {
    alias: '/r/:id',
    children: [{
      component: () => import(/* webpackChunkName: "nps" */ './RatingRoom.vue'),
      meta: {
        label: 'doctoroffice.room',
        secure: false,
      },
      path: '',
    }],
    component: () => import(/* webpackChunkName: "nps" */ '../common/CommonFullLayout.vue'),
    meta: {
      secure: false,
      visible: false,
    },
    path: '/rating/:id',
  },
  {
    children: [{
      component: () => import(/* webpackChunkName: "end" */ './EndRoom.vue'),
      meta: {
        label: 'doctoroffice.end',
        secure: false,
      },
      path: '',
    }],
    component: () => import(/* webpackChunkName: "end" */ '../common/CommonView.vue'),
    meta: {
      secure: false,
      visible: false,
    },
    path: '/end/:id',
  },
]);

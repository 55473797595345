/**
 * Módulo comum de domínio do sistema
 * Author: Luciano Jesus Lima <lucianojl@primeit.com.br>
 */
import { CommonState } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_CID10: async (context, params) => {
      let json;
      try {
        const response = await fetch(`${process.env.VUE_APP_COMMON_DOMAINS_SERVER_BASE}/CID10.json`);
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          empty: {},
          error: {
            code: 500,
            message: ['Impossível acessar o servidor. Tente novamente'],
          },
        };
      }
      context.commit('POPULATE_CID10', json);
    },

  },
  getters: {
    GET_CID10: state => state.cid10,
  },
  mutations: {
    POPULATE_CID10: (state, payload) => {
      if (payload) {
        state.cid10 = payload;
      }
    },
  },
  namespaced: true,
  state: {
    cid10: null,
  },
};

CommonState.registerModule('domain', module);

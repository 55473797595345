import { Config } from '@dav/security-component-spa';
import { API, CommonState } from '@primeit/components-vue';

const module = {
  actions: {
    ACCESSROOM: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/accessroom';

      const config = await Config.getInstance();

      const myInit = {
        headers: {
          'x-dav-company-id': config.get('dav-company-id'),
        },
        queryStringParameters: {
          access: params.id,
        },
        response: true,
      };

      let json = false;

      try {
        const payload = await API.get(apiName, path, myInit);
        if (payload && payload.status === 200) {
          sessionStorage.setItem('x-auth-room-participant', payload.data['x-auth-room-participant']);
          json = payload.data;
        }
      } catch (error) {
        if (error.response && error.response.data) {
          context.commit('common/POPULATE_FEEDBACK', error.response.data, {
            root: true,
          });
          json = error.response.data;
        } else {
          json = {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          };
        }
      }

      context.commit('POPULATE_ACCESSROOM', json);
    },

    DELETE_CHECKIN: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/checkin';

      const myInit = {
        body: params,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: { },
        response: true,
      };

      const json = await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          context.commit('common/POPULATE_FEEDBACK', error.response.data, {
            root: true,
          });
          // console.log(error.response);
          return false;
        });

      if (json) {
        return json;
      }
    },

    FETCH_ACCEPT_TERM: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/acceptterm';

      const myInit = {
        body: params,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        response: true,
      };

      const json = await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_TERM_USER', json);
    },
    FETCH_ACCOMPLISH: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/accomplish';

      const myInit = {
        body: {},
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      const json = await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_ACCOMPLISH_UPSHOT: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/upshot';

      const myInit = {
        body: params.upshotAttendance,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      return await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });
    },
    FETCH_ALLOW_ACCESS: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/allowaccess';

      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
          save_log: params.save_log,
        },
        response: true,
      };

      return API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((_) => {
          return {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          };
        });
    },
    FETCH_ANALYTICS: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/analytics';

      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
          latitude: params.latitude,
          longitude: params.longitude,
        },
        response: true,
      };

      API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          context.commit('common/POPULATE_FEEDBACK', error.response.data, {
            root: true,
          });
          // console.log(error.response);
          return false;
        });
    },

    FETCH_CHECKIN: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/checkin';

      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params,
        },
        response: true,
      };

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          context.commit('common/POPULATE_FEEDBACK', error.response.data, {
            root: true,
          });
          // console.log(error.response);
          return false;
        });

      if (json) {
        context.commit('POPULATE_ONE', json);
      }
    },

    FETCH_DELETE_FILE: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/file';

      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          filePath: params.filePath,
          id: params.id,
        },
        response: true,
      };

      const json = await API.del(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_FILE', json);
    },

    FETCH_DISPLAY_POWERED_BY: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/displaypoweredby';

      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params,
        },
        response: true,
      };

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_DISPLAY_POWERED_BY', json);
    },

    FETCH_GET_NOTES: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/notes';
      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            context.commit('POPULATE_NOTES', payload.data);
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return error.response.data;
          }
        });
    },

    FETCH_GET_TOKENTESTINGCONNECTION: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/testconnection';
      const myInit = {
        headers: {},
        response: true,
      };

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            return error.response.data;
          }
        });

      context.commit('POPULATE_TOKEN_PRECALL', json);
    },

    FETCH_PARTICIPANT_HISTORY: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/participanthistory';

      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: params,
        response: true,
      };

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          context.commit('common/POPULATE_FEEDBACK', error.response.data, {
            root: true,
          });
          // console.log(error.response);
          return false;
        });

      if (json) {
        json.id
          ? context.commit('POPULATE_PARTICIPANT_HISTORY_IN_ONE_APP', json)
          : context.commit('POPULATE_PARTICIPANT_HISTORY', json);
      }
    },

    FETCH_PARTICIPANT_LAST_HISTORY: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/participanthistory';

      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: params,
        response: true,
      };

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          context.commit('common/POPULATE_FEEDBACK', error.response.data, {
            root: true,
          });
          // console.log(error.response);
          return false;
        });

      if (json) {
        context.commit('POPULATE_PARTICIPANT_LAST_HISTORY', json);
      }
    },

    FETCH_RECORD_START: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/record/start';

      const myInit = {
        body: {},
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      const json = await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_FILE_RECORD', json);
    },

    FETCH_RECORD_STOP: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/record/stop';

      const myInit = {
        body: {},
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          archiveId: params.archiveId,
          id: params.id,
        },
        response: true,
      };

      const json = await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_FILE_RECORD', json);
    },

    FETCH_SAVE_MESSAGE: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/message';

      const myInit = {
        body: params.message,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      const json = await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      return json;
    },

    FETCH_SAVE_NOTES: async (context, params) => {
      const apiName = 'RoomAPI';

      const myInit = {
        body: params.body,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      const json = await API.put(apiName, params.path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_FEEDBACK_SAVE_NOTE', json);
    },

    FETCH_SAVE_NPS: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/nps';

      const myInit = {
        body: params.body,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
          protocol: params.protocol,
        },
        response: true,
      };

      const json = await API.post(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_SAVE_PRESCRIPTION_DATA: async (context, params) => {
      const apiName = 'RoomAPI';

      const myInit = {
        body: params.body,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      const json = await API.put(apiName, params.path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },

    FETCH_SAVE_TESTINGCONNECTION: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/testconnection';

      const myInit = {
        body: params.payload,

        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      const json = await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_FEEDBACK_SAVE_NOTE', json);
    },
    FETCH_SEND_FILE: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/file';

      const myInit = {
        body: params.file,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
        },
        response: true,
      };

      const json = await API.put(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return false;
          } else {
            return false;
          }
        });

      context.commit('POPULATE_FILE', json);
    },
    FETCH_VERIFY_EXISTING_NPS: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/nps';

      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params.id,
          protocol: params.protocol,
        },
        response: true,
      };

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      return json;
    },
    FETCH_WAITINGROOM: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/waitingroom';

      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          id: params,
        },
        response: true,
      };

      const json = await API.get(apiName, path, myInit)
        .then((payload) => {
          if (payload.status === 200) {
            return payload.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            context.commit('common/POPULATE_FEEDBACK', error.response.data, {
              root: true,
            });
            return error.response.data;
          } else {
            return {
              code: 400,
              message: 'Impossível acessar o servidor. Tente novamente',
            };
          }
        });

      context.commit('POPULATE_WAITINGROOM', json);
    },
  },
  getters: {
    FEEDBACK_SAVE_NOTE: (state) => {
      return JSON.parse(JSON.stringify(state.saveNote));
    },
    GET_ACCESSROOM: (state) => state.accessroom,
    GET_DISPLAY_POWERED_BY: (state) => {
      return JSON.parse(JSON.stringify(state.displayPoweredBy));
    },
    GET_FILE: (state) => {
      return JSON.parse(JSON.stringify(state.file));
    },
    GET_FILE_RECORD: (state) => {
      return JSON.parse(JSON.stringify(state.fileRecord));
    },
    GET_NOTES: (state) => {
      if (state.notes) {
        return JSON.parse(JSON.stringify(state.notes));
      }
    },
    GET_ONE: (state) => {
      return JSON.parse(JSON.stringify(state.entity));
    },
    GET_PARTICIPANT_HISTORY: (state) => {
      return JSON.parse(JSON.stringify(state.participant_history));
    },
    GET_PARTICIPANT_LAST_HISTORY: (state) => {
      return JSON.parse(JSON.stringify(state.participant_last_history));
    },
    GET_TOKEN_PRECALL: (state) => state.precall,
    GET_WAITINGROOM: (state) => {
      return JSON.parse(JSON.stringify(state.WAITINGROOM));
    },
    TERM_USER: (state) => state.term_user,
  },
  mutations: {
    POPULATE_ACCESSROOM: (state, payload) => {
      if (payload) {
        state.accessroom = payload;
      }
    },
    POPULATE_DISPLAY_POWERED_BY: (state, payload) => {
      if (payload) {
        state.displayPoweredBy = payload;
      }
    },
    POPULATE_FEEDBACK_SAVE_NOTE: (state, payload) => {
      if (payload) {
        state.saveNote = payload;
      }
    },
    POPULATE_FILE: (state, payload) => {
      if (payload) {
        state.file = payload;
      }
    },
    POPULATE_FILE_RECORD: (state, payload) => {
      if (payload) {
        state.fileRecord = payload;
      }
    },
    POPULATE_NOTES: (state, payload) => {
      if (payload) {
        state.notes = payload;
      }
    },
    POPULATE_ONE: (state, payload) => {
      if (payload) {
        state.entity = payload;
      }
    },
    POPULATE_PARTICIPANT_HISTORY: (state, payload) => {
      if (payload) {
        state.participant_history = payload;
      }
    },
    POPULATE_PARTICIPANT_HISTORY_IN_ONE_APP: (state, payload) => {
      if (payload) {
        payload.alreadyGotInfo = true;
        const index = state.participant_history.findIndex((item) => item.id === payload.id);
        if (index !== -1) state.participant_history.splice(index, 1, payload);
      }
    },
    POPULATE_PARTICIPANT_LAST_HISTORY: (state, payload) => {
      if (payload) {
        state.participant_last_history = payload;
      }
    },
    POPULATE_TERM_USER: (state, payload) => {
      if (payload) {
        state.term_user = payload;
      }
    },
    POPULATE_TOKEN_PRECALL: (state, payload) => {
      if (payload) {
        state.precall = payload;
      }
    },
    POPULATE_WAITINGROOM: (state, payload) => {
      if (payload) {
        state.WAITINGROOM = payload;
      }
    },
  },
  namespaced: true,
  state: {
    accessroom: null,
    displayPoweredBy: null,
    entity: null,
    file: null,
    fileRecord: null,
    new: [],
    notes: undefined,
    participant_history: null,
    participant_last_history: null,
    precall: null,
    saveNote: null,
    term_user: [],
    validate: null,
    WAITINGROOM: null,
  },
};

CommonState.registerModule('appointment', module);

// import './sentry.io';

import Vue from 'vue';
import vuetify from '@/vuetify';
import VueRouter from 'vue-router';
import VueTheMask from 'vue-the-mask';
import 'intersection-observer'; // safari 6+

import App from './App.vue';

// Componente
import PrimeIT, { CommonState, CommonRouter } from '@primeit/components-vue';

// Pages
import '@/pages';

// States
import '@/state';

// Vue Setup
Vue.use(PrimeIT);
Vue.use(VueRouter);
Vue.use(VueTheMask);
// Vue.use(PluginDialogConfirm, { vuetify });

/* eslint-disable no-new */
new Vue({
  render: (h) => h(App),
  router: CommonRouter,
  store: CommonState,
  vuetify,
}).$mount('#app');

import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([{
  alias: '/w',
  children: [{
    component: () => import(/* webpackChunkName: "waitingroom" */ './WaitingRoom.vue'),
    meta: {
      label: 'waitingroom.room',
      secure: false,
    },
    path: ':id',
  },
  {
    component: () => import(/* webpackChunkName: "waitingroom" */ './WaitingRoom.vue'),
    meta: {
      label: 'waitingroom.room',
      secure: false,
    },
    path: '',
  }],
  component: () => import(/* webpackChunkName: "waitingroom" */ '../common/CommonFullLayout.vue'),
  meta: {
    secure: false,
    visible: false,
  },
  path: '/waitingroom',
}]);

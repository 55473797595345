import { CommonState } from '@primeit/components-vue';

const module = {
  actions: {
    SKIP_POPULATE_CURRENT_USER (context, params) {
      context.commit('SKIP_POPULATE_CURRENT_USER', null);
    },
  },
  getters: {
    FEEDBACK: state => state.feedback,
  },
  mutations: {
    POPULATE_FEEDBACK: (state, payload) => {
      if (payload) {
        state.feedback = payload;
      }
    },
    SKIP_POPULATE_CURRENT_USER (context, params) {
      CommonState.currentUser = CommonState.setItem('current_user', {});
    },
  },
  namespaced: true,
  state: {
    feedback: null,
  },
};

CommonState.registerModule('common', module);

import { CommonState, API } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_SCREENING: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/screening';
      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        response: true,
      };

      const json = await API.get(apiName, path, myInit).then(payload => {
        if (payload.status === 200) {
          return payload.data;
        } else {
          return false;
        }
      }).catch(error => {
        if (error.response && error.response.data) {
          context.commit('common/POPULATE_FEEDBACK', error.response.data, {
            root: true,
          });
          return error.response.data;
        } else {
          return {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          };
        }
      });

      context.commit('POPULATE_SCREENING', json);
    },
  },
  getters: {
    GET_SCREENING: (state) => {
      if (state.screening) {
        return JSON.parse(JSON.stringify(state.screening));
      }
    },
  },
  mutations: {
    POPULATE_SCREENING: (state, payload) => {
      if (payload) {
        state.screening = payload;
      }
    },
  },
  namespaced: true,
  state: {
    screening: undefined,
  },
};

CommonState.registerModule('screening', module);

import { Config } from '@dav/security-component-spa';
import { API, CommonState } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_SAVE: async (context, params) => {
      const config = await Config.getInstance();
      const companyId = config.get('dav-company-id');

      const apiName = 'RoomAPI';
      const path = '/service-request';

      const myInit = {
        body: params,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
          'x-dav-company-id': companyId,
        },
        response: true,
      };

      let json;
      try {
        const response = await API.post(apiName, path, myInit);

        if (response?.status === 201) {
          json = response.data;
        }
      } catch (err) {
        if (err?.response?.data) {
          context.commit('common/POPULATE_FEEDBACK', err.response.data, {
            root: true,
          });
          return err.response.data;
        } else {
          return {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          };
        }
      }

      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
  },
  getters: {},
  mutations: {},
  namespaced: true,
  state: {},
  strict: true,
};


CommonState.registerModule('serviceRequest', module);

import { CommonRouter } from '@primeit/components-vue';

CommonRouter.registerRoutes([{
  children: [{
    component: () => import(/* webpackChunkName: "pageNotFound" */ './pageNotFound.vue'),
    meta: {
      label: 'pageNotFound.title',
    },
    path: '*',
  }],
  component: () => import(/* webpackChunkName: "pageNotFound" */ '../common/CommonFullLayout.vue'),
  meta: {
    order: 600,
    visible: false,
  },
  path: '*',
}]);

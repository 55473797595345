import { Config } from '@dav/security-component-spa';
import { API, CommonState } from '@primeit/components-vue';

const module = {
  actions: {
    FETCH_ALL_CUSTOM_MESSAGES: async (context, params) => {
      const apiName = 'RoomAPI';
      const path = '/custommessage';
      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
        },
        queryStringParameters: {
          fields: params.fields,
          id: params.id,
        },
        response: true,
      };

      const json = await API.get(apiName, path, myInit).then(payload => {
        if (payload.status === 200) {
          return payload.data;
        } else {
          return false;
        }
      }).catch(error => {
        if (error.response && error.response.data) {
          context.commit('common/POPULATE_FEEDBACK', error.response.data, {
            root: true,
          });
          return error.response.data;
        } else {
          return {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          };
        }
      });

      context.commit('POPULATE_ALL_CUSTOM_MESSAGES', json);
    },

    FETCH_COMPANY_SETTINGS: async (context) => {
      const config = await Config.getInstance();
      const companyId = config.get('dav-company-id');

      const apiName = 'RoomAPI';
      const path = `/company/${companyId}/settings`;
      const myInit = {
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
          'x-dav-company-id': companyId,
        },
        response: true,
      };
      let json;
      try {
        const response = await API.get(apiName, path, myInit);
        if (response?.status === 200) {
          json = response.data;
        }
      } catch (err) {
        if (err?.response?.data) {
          context.commit('common/POPULATE_FEEDBACK', err.response.data, {
            root: true,
          });
          return err.response.data;
        } else {
          return {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          };
        }
      }
      context.commit('POPULATE_COMPANY_SETTINGS', json);
    },

    FETCH_SAVE_COMPANY_SETTINGS: async (context, params) => {
      const config = await Config.getInstance();
      const companyId = config.get('dav-company-id');

      const apiName = 'RoomAPI';
      const path = `/company/${companyId}/settings`;
      const myInit = {
        body: params.settings,
        headers: {
          'x-auth-room-participant': sessionStorage.getItem('x-auth-room-participant'),
          'x-dav-company-id': companyId,
        },
        response: true,
      };
      let json;
      try {
        const response = await API.put(apiName, path, myInit);

        if (response?.status === 200) {
          json = response.data;
        }
      } catch (err) {
        if (err?.response?.data) {
          context.commit('common/POPULATE_FEEDBACK', err.response.data, {
            root: true,
          });
          return err.response.data;
        } else {
          return {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          };
        }
      }
      context.commit('POPULATE_COMPANY_SETTINGS', json);
    },
  },
  getters: {
    GET_ALL_CUSTOM_MESSAGES: (state) => {
      return JSON.parse(JSON.stringify(state.customMessages));
    },

    GET_COMPANY_SETTINGS: (state) => {
      return JSON.parse(JSON.stringify(state.settings));
    },
  },
  mutations: {
    POPULATE_ALL_CUSTOM_MESSAGES: (state, payload) => {
      if (payload) {
        state.customMessages = payload;
      }
    },

    POPULATE_COMPANY_SETTINGS: (state, payload) => {
      if (payload) {
        state.settings = payload;
      }
    },
  },
  namespaced: true,
  state: {
    customMessages: null,
    settings: null,
  },
};

CommonState.registerModule('company', module);
